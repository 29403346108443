import React from 'react'
import Layout from '../../components/layout'
import ServiceIntroduction from '../../components/serviceIntroduction'
import Seo from "../../components/Seo";
import { graphql, useStaticQuery } from "gatsby";

const Saneeraus = () => {

const saneerausSeoPic = useStaticQuery(graphql`
    query saneerausSeoPic {
      image: file(relativePath: { eq: "square/Ylitalo-09.jpg" }) {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

    return (
        <Layout>
    <Seo
        title="Saneeraus | Ylitalon"
        description="Huoneisto-, julkisivu- ja ikkunaremontit. Toteutamme ammattitaidolla haastavat saneeraukset ja remontoinnit taloyhtiöissä sekä omakotitaloissa Lahden alueella."
        keywords={["huoneistoremontit", "julkisivuremontti", "kattoremontti", "ikkunaremontti", "muuraustyöt", "kylpyhuoneremontti", "saunaremontti", "Lahti", "Lahden talousalue", "Porvoo", "Orimattila", "Hollola"]}
        url="https://ylitalon.netlify.app/palvelut/saneeraus"
        image={"https://ylitalon.netlify.app" + saneerausSeoPic.image.publicURL}
      />
            <ServiceIntroduction
                to={"/palvelut"}
                src={"square/Ylitalo-19.jpg"}
                title={"Saneeraus"}
                titleSize={[10, 18]}
                smallTitle1={"Hajut pois otsonoinnilla"}
                mediumTitle={"Ammattitaitoista remontointia"}
                infoText1={"Toteutamme laadukkaasti haastavatkin remontoinnit ja saneeraukset niin omakotitaloissa kuin taloyhtiöissäkin. Tarvittaessa saat kauttamme suunnittelupalvelun ja haemme puolestasi tarvittavat rakennus- tai toimenpideluvat."}
                listTitle={"Palveluihimme muun muassa kuuluu:"}
                icon={"_"}
                infoText2={"Otsoni (O3) on väritön, pistävän raikkaan hajuinen ja ärsyttävä yhdiste. Otsoni poistaa epäpuhtauksia hapettamalla. Otsonointi onkin moderni tapa desinfioida huoneiston pinnat ja poistaa epämiellyttävät hajut. Esimerkiksi tupakoidun asunnon hajunpoisto onnistuu otsonoimalla ilman kallista remontointia."}
                linkTo={"_"}
                list={['Huoneistoremontit', 'Kylpyhuone- ja saunatilat', 'Muuraustyöt', 'Julkisivuremontit', 'Ikkuna- ja oviremontit', 'Lisälämmöneristykset', 'Kattoremontit', 'Julkisivumaalaukset ja -pinnoitukset', 'Kattojen pesut, maalaukset ja pinnoitukset', 'Maalaukset', 'Tasoitetyöt käsin tai ruiskulla']}
            />
        </Layout>
    )
}

export default Saneeraus