import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colors, bp } from '../styles/colors'
import ServiceImage from './serviceImage'
import { GreyText, BlackText } from '../styles/fonts'
import BackButton from './backButton'
import { SmallTitle, MediumTitle } from '../styles/fonts'

const ServiceIntroduction = (props) => {

    console.log(!props.icon === "_")

    return(
        <SectionWrapper>
            
            <ServiceHero>
            <BackButton to={props.to}></BackButton>
                <SmallTitle style={{marginBottom: '1rem'}}>Palveluesittely</SmallTitle>
                <ServiceHeroTittles>
                    <TitleWrapper>
                        <MediumTitle>{props.mediumTitle}</MediumTitle>
                    </TitleWrapper>
                    <HeroTextWrapper>
                        <ServiceInfoText>{props.infoText1}</ServiceInfoText>
                        {!(props.listTitle === "_") &&
                            <ServiceListHeader style={{fontWeight: '900'}}>Palveluihimme muun muassa kuuluu:</ServiceListHeader>
                        }
                        {!(props.list === "_") &&
                            <ServiceList>
                                {props.list.map((n) => <ServiceItem>{n}</ServiceItem>)}
                            </ServiceList>
                        }
                    </HeroTextWrapper>
                </ServiceHeroTittles>
            </ServiceHero>

            <ServiceWrapper>
                <Bigtitle size={props.titleSize}>{props.title}</Bigtitle>
                <ServiceBox>
                    <BlackBlock/>
                    <ServiceInfoWrapper>
                        <ServiceImg  src={props.src}/>
                        <ServiceInfoBox>
                            <InfoTitleWrapper>
                                {!(props.icon === "_") &&
                                <IconContainer><img src={props.icon} width="50px" alt="icon"/></IconContainer>
                                }
                                <ServiceInfoTitle>{props.smallTitle1}</ServiceInfoTitle>
                            </InfoTitleWrapper>
                            <ServiceInfoText>
                                {props.infoText2}
                                {!(props.linkTo === "_") &&
                                    <RefLink to={props.linkTo[1]}>{props.linkTo[0]}</RefLink>
                                }
                            </ServiceInfoText>
                        </ServiceInfoBox>
                    </ServiceInfoWrapper>
                </ServiceBox>
            </ServiceWrapper>
        </SectionWrapper>
    )
}

export default ServiceIntroduction 


const SectionWrapper = styled.div`
    margin: 3rem 0rem 10rem 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: ${bp.mobile}) {
        margin: 3rem 0rem 3rem 0;
    }
`
const ServiceHero = styled.div`
    max-width: 70%;
    margin: 0rem auto 3rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: ${bp.large}) {
        max-width: 80%;
    }
    @media screen and (max-width: ${bp.mobile}) {
        max-width: 90%;
        margin: 10rem auto 2rem auto;
    }
`
const ServiceHeroTittles = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1 40%;
    
`
const TitleWrapper = styled.div`
    max-width: 600px;
    display: flex;
    flex-direction: column;
    max-width: 50%;
    justify-content: flex-start;@media screen and (max-width: ${bp.mobile}) {
        max-width: 90%;
    }
`
const HeroTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 32%;
    margin: 1rem 0;
    @media screen and (max-width: ${bp.mobile}) {
        max-width: 100%;
        width: 100%;
    }
`
const ServiceListHeader = styled(GreyText)`
    margin-top: 2rem;
    margin-bottom: 1rem;
`
const ServiceList = styled.div`
    
`
const ServiceItem = styled.li`
    color: ${colors.BlackText};
    font-size: 1.1rem;
    list-style: inside;
    margin-bottom: 0.3rem;
`


const Bigtitle = styled.h1`
    font-size: ${props => props.size[0]}rem;
    font-weight: 900;
    color: ${colors.grey7};
    transform: translateY(20%);
    @media screen and (max-width: ${bp.mobile}) {
        font-size: ${props => props.size[1]}vw;;
    }
`
const ServiceWrapper = styled.div`
    background: ${props => props.dark ? colors.dark : 'transparent'};
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0rem 0;
    align-self: center;
`
const ServiceInfoWrapper = styled.div`
    display: flex;
    background-color: ${colors.white};
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow:      0px 0px 10px rgba(0, 0, 0, 0.05),
                        0px 0px 80px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: ${bp.mobile}) {
        flex-direction: column;
    }
`
const ServiceBox = styled.div`
    display: block;
    padding: 3rem;
    position: relative;
    overflow: visible;
    @media screen and (max-width: ${bp.mobileSmall}) {
        padding: 2rem;
    }
`
const BlackBlock = styled.div`
    background-color: ${colors.black};
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 30%;
    @media screen and (max-width: ${bp.mobile}) {
        width: 100%;
        height: 40%;
    }
    @media screen and (max-width: ${bp.mobileSmall}) {
        height: 30%;
    }
`

const ServiceInfoBox = styled.div`
    display: flex;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-self: flex-end;
    align-self: center;
    padding: 2rem;
`
const InfoTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    
`
const ServiceInfoTitle = styled.h3`
    font-weight: bold;
    font-size: 24px;
    color: ${props => props.dark ? colors.grey6 : colors.dark};
`
const ServiceInfoText = styled(BlackText)`
    max-width: 500px;
`
const ServiceImg = styled(ServiceImage)`
`
const IconContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    margin-left: -0.8rem;
    margin-top: -0.5rem;
`
const RefLink = styled(Link)`
    font-weight: 600;
    color:black;
`