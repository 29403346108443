import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { bp } from '../styles/colors';
import styled from 'styled-components'

function renderImage(image) {
  console.log(image + "  --  " + getImage(image.node))
  return (
    <StyledImg image={getImage(image.node)} alt="rtst"/>
  )
}

const ServiceImage = function ({ src }) {

  return <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { relativeDirectory: { eq: "square" } }) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
            }
          }
        }
      }
    }
    `}
    render={(data) => {
      const image = data.images.edges.find(
        image => image.node.relativePath === src
      )
      console.log(data.images.edges)
      return(renderImage(image))
    }}
  />
}
export default ServiceImage;

const StyledImg = styled(GatsbyImage)`
  clip-path: polygon(0 0%, 100% 0%, 83% 100%, 0% 100%);
  max-height: 400px;
  max-width: 400px;
  overflow: hidden;
  @media screen and (max-width: ${bp.mobile}) {
    clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    max-width: 700px;
  }

`